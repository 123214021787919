import styled from 'styled-components';
import { colors } from 'constants/colors';
import { Title } from '../../Typography';
import { FontWeight } from '../../../theme';
import { hideBeforeMd } from '../../../theme/helpers';
import { mediaSmDown } from '../../../theme/helpers/css';

export const Container = styled.div`
  background-color: ${colors.mainLandingPurple};
  margin: 0 11px;
  height: 704px;
  border-bottom-left-radius: 180px;
  border-bottom-right-radius: 180px;
  position: relative;

  ${mediaSmDown`
      height: auto;
      border-bottom-left-radius: 60px;
      border-bottom-right-radius: 60px;
  `}
`;

export const ManImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  border-bottom-right-radius: 180px;
  ${hideBeforeMd}
`;

export const Block = styled.div`
  padding: 217px 156px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  ${mediaSmDown`
    padding: 20px;
  `}
`;

export const MainTitle = styled(Title)`
  font-size: 40px;
  line-height: 46px;
  max-width: 700px;
  font-weight: ${FontWeight.extraBold};
  color: ${colors.blackRussian};
`;

export const Description = styled(Title)`
  font-size: 18px;
  line-height: 24px;
  max-width: 700px;
  font-weight: ${FontWeight.medium};
  color: ${colors.blackRussian};
`;

export const SearchAddressWrapper = styled.div`
  margin-top: 40px;

  ${mediaSmDown`
   margin-top: 10px;
  `}
`;
