import styled from 'styled-components';
import ClosableModal from './ClosableModal';

const FullClosableModal = styled(ClosableModal)`
  padding: 0;
  position: relative;
  overflow: auto;
`;

export default FullClosableModal;
