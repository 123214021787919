import React, { FC, useMemo } from 'react';
import { BottomSheetSAContext, ShopsSAContext } from 'Components/landings/LandingSearchAddress/context';
import useModalState from 'utils/hooks/useModalState';

interface ISelectAddressProvider {
  children: React.ReactChild | React.ReactNode;
}

const SelectAddressProvider: FC<ISelectAddressProvider> = ({ children }) => {
  const { isVisible: isOpenBottomSheet, showModal: openBottomSheetSA, hideModal: closeBottomSheetSA } = useModalState();
  const { isVisible: isVisibleShops, showModal: showShops, hideModal: hideShops } = useModalState();
  const bottomSheetContextValue = useMemo(() => {
    return { isOpenBottomSheet, openBottomSheetSA, closeBottomSheetSA };
  }, [isOpenBottomSheet, openBottomSheetSA, closeBottomSheetSA]);
  const shopsContextValue = useMemo(() => {
    return { isVisibleShops, showShops, hideShops };
  }, [isVisibleShops, showShops, hideShops]);

  return (
    <BottomSheetSAContext.Provider value={bottomSheetContextValue}>
      <ShopsSAContext.Provider value={shopsContextValue}>{children}</ShopsSAContext.Provider>
    </BottomSheetSAContext.Provider>
  );
};

export default SelectAddressProvider;
