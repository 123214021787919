import React, { FC } from 'react';
import FullClosableModal from 'Components/Modal/FullClosableModal';
import { FullModal } from 'Containers/Modals/styled';
import useGetParameter from 'utils/hooks/useGetParameter';
import { useHistory } from 'react-router';
import loadable from '@loadable/component';
import FullPageLoading from 'Containers/Loading/FullPageLoading';
import { clearSAFromLocation } from './utils';
import { useSelector } from 'react-redux';
import { checkIsCoordinate } from 'Modules/SearchAddress/selectors';
import { statesSA } from 'Containers/SelectAddress/constants';

const SelectAddressModal = loadable(
  () => import(/* webpackChunkName: "SelectAddressModal" */ 'Containers/SelectAddress/SelectAddress'),
  {
    fallback: <FullPageLoading />,
  }
);

const LandingSelectAddress: FC = () => {
  const isOpen = useGetParameter('select_address');
  const history = useHistory();
  const addressWithCoordinate = useSelector(checkIsCoordinate);

  const handleHideModal = () => {
    clearSAFromLocation(history);
  };

  return (
    <FullClosableModal show={Boolean(isOpen)} closeModal={handleHideModal} isHideCloseBtn>
      <FullModal>
        <SelectAddressModal
          onHideSelectAddress={handleHideModal}
          withCloseBtn
          {...(addressWithCoordinate && { initialStateSA: statesSA.selectShop })}
        />
      </FullModal>
    </FullClosableModal>
  );
};

export default LandingSelectAddress;
