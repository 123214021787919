import { createContext } from 'react';

interface IBottomSheetSAContext {
  isOpenBottomSheet: boolean;
  openBottomSheetSA: () => void;
  closeBottomSheetSA: () => void;
}

interface IShopsSAContext {
  isVisibleShops: boolean;
  showShops: () => void;
  hideShops: () => void;
}

export const BottomSheetSAContext = createContext<IBottomSheetSAContext>(undefined!);
export const ShopsSAContext = createContext<IShopsSAContext>(undefined!);
