import React from 'react';
import backgroundImgSrc from './images/background.png';
import productsImgSrc from './images/products.png';
import { EnumTheme, ModalType } from '../../../constants/enums';
import ModalActions from '../../../Modules/Modals/actions';
import { useDispatch } from 'react-redux';
import {
  BackgroundImage,
  Container,
  Number,
  NumberContainer,
  ProductImage,
  Step,
  StepsRow,
  StyledButton,
  Text,
  Title,
} from './styled';

export const MainLandingRegistration = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(ModalActions.openModal(ModalType.Auth));
  };
  return (
    <Container>
      <BackgroundImage src={backgroundImgSrc} alt={'background'} />
      <ProductImage src={productsImgSrc} alt={'products'} />
      <Title>Да! Можно начать заказывать уже сегодня</Title>
      <StepsRow>
        <Step>
          <NumberContainer>
            <Number>1</Number>
          </NumberContainer>
          <Text>
            Укажите адрес доставки <br /> и выберите магазин
          </Text>
        </Step>
        <Step>
          <NumberContainer>
            <Number>2</Number>
          </NumberContainer>
          <Text>
            Зарегистрируйтесь <br />и получите код партнера
          </Text>
        </Step>
        <Step>
          <NumberContainer>
            <Number>3</Number>
          </NumberContainer>
          <Text>
            Оформите <br />и получите заказ
          </Text>
        </Step>
        <Step>
          <NumberContainer>
            <Number>4</Number>
          </NumberContainer>
          <Text>
            Оплатите счёт <br />в течение 7 рабочих дней
          </Text>
        </Step>
      </StepsRow>
      <StyledButton use={EnumTheme.roundRed} type="submit" onClick={handleClick}>
        Регистрация
      </StyledButton>
    </Container>
  );
};
