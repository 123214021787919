import React from 'react';
import fructsImgSrc from './images/fructs.png';
import vegetablesImgSrc from './images/vegetables.png';
import milkImgSrc from './images/milk.png';
import cookiesImgSrc from './images/cookies.png';
import waterImgSrc from './images/water.png';

import { Container, Title, CategoriesRow, Category, Text } from './styled';

export const MainLandingCategories = () => {
  return (
    <Container>
      <Title>Популярные категории</Title>
      <CategoriesRow>
        <Category>
          <img src={fructsImgSrc} alt="fructs" />
          <Text>
            Фрукты, ягоды и <br /> наборы
          </Text>
        </Category>
        <Category>
          <img src={vegetablesImgSrc} alt="vegetables" />
          <Text>
            Овощи, зелень, <br />
            грибы
          </Text>
        </Category>
        <Category>
          <img src={milkImgSrc} alt="milk" />
          <Text>Молочные продукты</Text>
        </Category>
        <Category>
          <img src={cookiesImgSrc} alt="cookies" />
          <Text>
            Кондитерские <br />
            изделия
          </Text>
        </Category>
        <Category>
          <img src={waterImgSrc} alt="water" />
          <Text>
            Бутилированная <br />
            вода
          </Text>
        </Category>
      </CategoriesRow>
    </Container>
  );
};
