import { useCallback, useRef, useState } from 'react';
import { useScroll } from 'utils/hooks';

export enum ScrollDirectionEnum {
  top = 'top',
  bottom = 'bottom',
}

const DEBOUNCE_TIME = 16;

export const getNewScrollDirection = (
  prevScrollTop: number,
  currentScrollTop: number,
  prevDirection: ScrollDirectionEnum
) => {
  if (prevScrollTop > currentScrollTop) {
    return ScrollDirectionEnum.top;
  }

  if (prevScrollTop < currentScrollTop) {
    return ScrollDirectionEnum.bottom;
  }

  return prevDirection;
};

const useScrollDirection = (
  scrollableElement: HTMLElement | null,
  callback?: (direction: ScrollDirectionEnum) => void,
  threshold = 500
) => {
  const prevScroll = useRef(0);
  const prevDirection = useRef(ScrollDirectionEnum.top);
  const [scrollDirection, setScrollDirection] = useState<ScrollDirectionEnum>(ScrollDirectionEnum.bottom);
  const [scrollTop, setScrollTop] = useState<number>(0);

  const handleScroll = useCallback(() => {
    if (scrollableElement) {
      setScrollTop(scrollableElement.scrollTop);

      if (Math.abs(prevScroll.current - scrollableElement.scrollTop) < threshold) return;

      const nextScrollDirection = getNewScrollDirection(
        prevScroll.current,
        scrollableElement.scrollTop,
        prevDirection.current
      );

      prevScroll.current = scrollableElement.scrollTop;

      if (nextScrollDirection !== prevDirection.current) {
        prevDirection.current = nextScrollDirection;

        callback && callback(nextScrollDirection);
        setScrollDirection(nextScrollDirection);
      }
    }
  }, [callback, scrollableElement, threshold]);

  useScroll(handleScroll, scrollableElement, DEBOUNCE_TIME);

  return { scrollDirection, scrollTop };
};

export default useScrollDirection;
