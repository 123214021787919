import React from 'react';
import Icon, { IIcon } from './Icon';

const PaymentMethod: React.FC<IIcon> = props => (
  <Icon width="64" height="64" viewBox="0 0 64 64" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.0158 24.379L28.0158 31.1523H32.2502C34.1206 31.1523 35.6369 29.636 35.6369 27.7656C35.6369 25.8953 34.1206 24.379 32.2502 24.379H28.0158ZM28.0159 34.9629H32.2502C36.2252 34.9629 39.4475 31.7406 39.4475 27.7656C39.4475 23.7907 36.2252 20.5684 32.2502 20.5684H27.3807C25.6269 20.5684 24.2051 21.9901 24.2052 23.7439L24.2052 31.1523H22.2998C21.2475 31.1523 20.3945 32.0053 20.3945 33.0576C20.3945 34.1099 21.2475 34.9629 22.2998 34.9629H24.2052L24.2052 36.0932H22.2998C21.2475 36.0932 20.3945 36.9462 20.3945 37.9985C20.3945 39.0508 21.2475 39.9038 22.2998 39.9038H24.2052L24.2052 41.5269C24.2052 42.5792 25.0583 43.4322 26.1106 43.4322C27.1629 43.4322 28.0159 42.5792 28.0159 41.5269L28.0159 39.9038H31.8264C32.8787 39.9038 33.7318 39.0508 33.7318 37.9985C33.7318 36.9462 32.8787 36.0932 31.8264 36.0932H28.0159L28.0159 34.9629Z"
      fill="#DA1F2A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 12C12.8954 12 12 12.8954 12 14V50C12 51.1046 12.8954 52 14 52H37.2509C39.4466 52 41.4669 50.8007 42.5183 48.8731L51.7558 32.9577C52.0814 32.3607 52.0814 31.6393 51.7558 31.0423L42.5183 15.1269C41.4669 13.1993 39.4466 12 37.2509 12H14ZM8 14C8 10.6863 10.6863 8 14 8H37.2509C40.9104 8 44.2776 9.99887 46.0299 13.2115L55.2674 29.1269C55.7558 30.0223 56 31.0112 56 32C56 32.9888 55.7558 33.9777 55.2674 34.8731L46.0299 50.7885C44.2776 54.0011 40.9104 56 37.2509 56H14C10.6863 56 8 53.3137 8 50V14Z"
      fill="#DA1F2A"
    />
  </Icon>
);

export default PaymentMethod;
