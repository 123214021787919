import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { FontFamily } from '../../../theme';
import { mediaSmDown } from '../../../theme/helpers/css';

export const Container = styled.div`
  background: ${colors.dark200};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 34px 0 45px;
  margin-top: 90px;
  gap: 2px;

  ${mediaSmDown`
     padding: 34px 20px 45px;
  `}
`;

export const TelLink = styled.a`
  text-decoration: none;
  color: ${colors.white};
  font-size: 24px;
  line-height: 38px;
`;

export const Text = styled.a`
  font-family: ${FontFamily.secondFont};
  text-decoration: none;
  color: ${colors.white};
  font-size: 18px;
  line-height: 24px;
`;
