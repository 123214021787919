import React from 'react';
import { Container, TelLink, Text } from './styled';

export const MainLandingFooter = () => {
  return (
    <Container>
      <TelLink href="tel:+74957295471">+7 (495)729-54-71</TelLink>
      <Text href="mailto:yest@komus.net">yest@komus.net</Text>
      <Text>ООО «Комус-Ростов», ИНН 6163069899</Text>
    </Container>
  );
};
