import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useRequest } from 'swr.config';
import { getCurrentCity } from 'Modules/SearchAddress/selectors';
import { IProductsGroup } from 'Modules/Shops/types';
import { URL } from 'constants/urlMaps';
import { useServerData } from 'serverDataContext';
import fetchDataOnServer from './index.server';
import SelectAddressProvider from 'Components/landings/LandingSearchAddress/SelectAddressProvider';
import SelectAddressModal from 'Components/landings/LandingSearchAddress/SelectAddressModal';
import { MainLandingTopBlock } from 'Components/landings/MainLandingTopBlock';
import { MainLandingInfoRow } from 'Components/landings/MainLandingInfoBloks';
import { MainLandingShops } from 'Components/landings/MainLandingShops';
import { MainLandingRegistration } from 'Components/landings/MainLandingRegistration';
import { MainLandingMap } from 'Components/landings/MainLandingMap';
import { MainLandingCategories } from 'Components/landings/MainLandingCategories';
import { MainLandingShopSelect } from 'Components/landings/MainLandingShopSelect';
import { MainLandingFooter } from 'Components/landings/MainLandingFooter';

export const SSR_KEY = 'Main';

const Main: React.FC = () => {
  const city = useSelector(getCurrentCity);
  const dataFromServer = useServerData(SSR_KEY, [city.id]);
  const { data: groups } = useRequest<IProductsGroup[]>([URL.shops_groups, { city_id: city.id }], {
    initialData: dataFromServer?.groups,
  });

  return (
    <>
      <Helmet>
        <title>Komus.Yest – заказ и доставка продуктов в офис в Москве.</title>
        <meta
          content="Доставка продуктов питания и товаров в Москве из магазинов партнеров. Комус Есть +7 (495) 729-54-71"
          name="description"
        />
        <meta
          content="Доставка продуктов питания и товаров в Москве из магазинов партнеров. Комус Есть +7 (495) 729-54-71"
          property="og:description"
        />
        <meta
          content="Доставка продуктов питания и товаров в Москве из магазинов партнеров. Комус Есть +7 (495) 729-54-71"
          name="twitter:description"
        />
        <meta content="Komus.Yest – заказ и доставка продуктов в офис в Москве." name="twitter:title" />
        <meta content="Komus.Yest – заказ и доставка продуктов в офис в Москве." name="og:title" />
        <link rel="canonical" href="https://yest.komus.ru/" />
      </Helmet>
      <SelectAddressProvider>
        <MainLandingTopBlock />
        <MainLandingInfoRow />
        <MainLandingShops groups={groups} />
        <MainLandingRegistration />
        <MainLandingCategories />
        <MainLandingMap />
        <MainLandingShopSelect />
        <MainLandingFooter />
        <SelectAddressModal />
      </SelectAddressProvider>
    </>
  );
};

Main.fetchOnServer = fetchDataOnServer;

export default Main;
