import styled from 'styled-components';
import { colors } from 'constants/colors';
import { FontFamily, FontWeight } from 'theme';
import { mediaSmDown } from 'theme/helpers/css';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 55px;
`;

export const TextContainer = styled.div`
  position: relative;
  text-align: center;
  ${mediaSmDown`
    padding: 0 20px;
  `}

  &:before {
    content: '';
    position: absolute;
    right: -10px;
    top: 22px;
    width: 379px;
    height: 23px;
    background: ${colors.mainLandingGreen};
    z-index: -1;
  }
`;

export const Text = styled.span`
  font-family: ${FontFamily.secondFont};
  font-size: 38px;
  font-weight: ${FontWeight.medium};
  line-height: 44px;
  text-align: center;
  color: ${colors.dark300};
  text-align: center;
`;

export const LogoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 70px;
  position: relative;
  margin-top: 26px;
  flex-wrap: wrap;

  ${mediaSmDown`
  padding: 0 20px
  `}
`;

export const SoonBlock = styled.div`
  position: absolute;
  right: 0;
  bottom: -20px;
  padding: 5px 20px;
  border: 1px solid ${colors.primaryMain};
  border-radius: 5px;
  background: ${colors.white};
`;

export const SoonText = styled.span`
  font-family: ${FontFamily.secondFont};
  font-size: 20px;
  font-weight: ${FontWeight.extraBold};
  line-height: 26px;
  color: ${colors.primaryMain};
  text-transform: uppercase;
`;

export const LogoItem = styled.div<{ imageSrc: string }>`
  box-sizing: border-box;
  margin-right: 5px;
  width: 234px;
  height: 130px;
  background-image: url(${({ imageSrc }) => imageSrc});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;
