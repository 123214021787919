import React from 'react';
import Icon, { IIcon } from './Icon';

const PaymentDelayIcon: React.FC<IIcon> = props => (
  <Icon width="64" height="64" viewBox="0 0 64 64" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 12C20.9543 12 12 20.9543 12 32C12 43.0457 20.9543 52 32 52C33.1046 52 34 52.8954 34 54C34 55.1046 33.1046 56 32 56C18.7452 56 8 45.2548 8 32C8 18.7452 18.7452 8 32 8C45.2548 8 56 18.7452 56 32C56 33.1046 55.1046 34 54 34C52.8954 34 52 33.1046 52 32C52 20.9543 43.0457 12 32 12Z"
      fill="#DA1F2A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 20C26 18.8954 26.8954 18 28 18H32V20C32 18 32.001 18 32.002 18L32.0042 18L32.009 18L32.0202 18.0001L32.0495 18.0004C32.0719 18.0008 32.1002 18.0014 32.134 18.0026C32.2015 18.0048 32.2914 18.009 32.4003 18.0168C32.6175 18.0323 32.9148 18.0624 33.2663 18.121C33.9598 18.2365 34.915 18.4714 35.8944 18.9611C36.8817 19.4548 37.9298 20.2266 38.7266 21.4218C39.5318 22.6296 40 24.15 40 26C40 27.85 39.5318 29.3704 38.7266 30.5782C37.9298 31.7734 36.8817 32.5452 35.8944 33.0389C34.915 33.5286 33.9598 33.7635 33.2663 33.879C32.9148 33.9376 32.6175 33.9677 32.4003 33.9832C32.2914 33.991 32.2015 33.9952 32.134 33.9974C32.1002 33.9986 32.0719 33.9992 32.0495 33.9996L32.0202 33.9999L32.009 34L32.0042 34L32.002 34C32.001 34 32 34 32 32V34H30V36H34C35.1046 36 36 36.8954 36 38C36 39.1046 35.1046 40 34 40H30V44C30 45.1046 29.1046 46 28 46C26.8954 46 26 45.1046 26 44V40H24C22.8954 40 22 39.1046 22 38C22 36.8954 22.8954 36 24 36H26V34H24C22.8954 34 22 33.1046 22 32C22 30.8954 22.8954 30 24 30H26V20ZM30 30H31.9878L32.0008 29.9996C32.0211 29.999 32.0602 29.9973 32.1153 29.9934C32.2262 29.9854 32.3977 29.9686 32.6087 29.9335C33.0402 29.8615 33.585 29.7214 34.1056 29.4611C34.6183 29.2048 35.0702 28.8516 35.3984 28.3594C35.7182 27.8796 36 27.15 36 26C36 24.85 35.7182 24.1204 35.3984 23.6407C35.0702 23.1484 34.6183 22.7952 34.1056 22.5389C33.585 22.2786 33.0402 22.1385 32.6087 22.0665C32.3977 22.0314 32.2262 22.0146 32.1153 22.0066C32.0602 22.0027 32.0211 22.001 32.0008 22.0004L31.9878 22H30V30Z"
      fill="#DA1F2A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.9971 52C49.3121 52 51.9995 49.3126 51.9995 45.9976C51.9995 42.6825 49.3121 39.9951 45.9971 39.9951C42.682 39.9951 39.9946 42.6825 39.9946 45.9976C39.9946 49.3126 42.682 52 45.9971 52ZM45.9971 56C51.5213 56 55.9995 51.5218 55.9995 45.9976C55.9995 40.4734 51.5213 35.9951 45.9971 35.9951C40.4729 35.9951 35.9946 40.4734 35.9946 45.9976C35.9946 51.5218 40.4729 56 45.9971 56Z"
      fill="#DA1F2A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.998 40.6636C47.1026 40.6636 47.998 41.559 47.998 42.6636V43.9977H49.3322C50.4368 43.9977 51.3322 44.8932 51.3322 45.9977C51.3322 47.1023 50.4368 47.9977 49.3322 47.9977H43.998V42.6636C43.998 41.559 44.8935 40.6636 45.998 40.6636Z"
      fill="#DA1F2A"
    />
  </Icon>
);

export default PaymentDelayIcon;
