import React, { useEffect, useState, FC, useContext } from 'react';
import styled from 'styled-components';
import ProfileButton from 'Components/Header/ProfileDropdown/ProfileButton';
import { Box, Flex } from '@igooods/ui-kit';
import BlockContainer from 'Components/landings/BlockContainer';
import CitiesDropdown from 'Components/Header/CitiesButton/CitiesDropdown';
import { colors } from 'constants/colors';
import IgooodsLogoIcon from 'Components/Header/Logo/img/komusLogo.svg';
import { mediaSmDown } from 'theme/helpers/css';
import { addStyleIfPropTruly } from 'utils/styledComponents';
import useModalState from 'utils/hooks/useModalState';
import { isBrowser } from 'utils/helpers';
import useScrollDirection, { ScrollDirectionEnum } from 'utils/hooks/useScrollDirection';
import { FontSize, Transitions } from 'theme';
import SelectShopDropdown, { IOnSelectShop } from 'Containers/SelectShop';
import { EnumSelectShopFrom } from 'Containers/SelectShop/constants';
import { ShopsSAContext } from 'Components/landings/LandingSearchAddress/context';
import { Link } from 'react-router-dom';
import { URL } from 'constants/urlMaps';
import { Text } from 'Components/Typography';
import ArrowRightIcon from 'Components/Icon/ArrowRightIcon';

export const LANDING_HEADER_SECTION_TEST_ID = 'LANDING_HEADER_SECTION_TEST_ID';
export const LANDING_AUTH_BTN_TEST_ID = 'LANDING_AUTH_BTN_TEST_ID';

const HEADER_HEIGHT = 82;
const MOBILE_HEADER_HEIGHT = 72;

const Wrapper = styled.div`
  height: ${HEADER_HEIGHT}px;

  ${mediaSmDown`
    height: ${MOBILE_HEADER_HEIGHT}px;
  `}
`;

const StickySection = styled(BlockContainer).attrs({
  px: { xs: 5, sm: 9 },
  bgColor: 'transparent',
})<{ $isActive: boolean; $isFixed: boolean }>`
  position: sticky;
  z-index: 10;
  top: -82px;
  transition: ${Transitions.top}, ${Transitions.color}, fill 3s ease, ${Transitions.opacity};
  width: 100vw;
  left: 0;

  ${mediaSmDown`
    top: -72px;
  `}

  ${addStyleIfPropTruly('$isActive', `&& { background-color: ${colors.white}; }`)}
  ${addStyleIfPropTruly(
    '$isFixed',
    `
      && {
        background-color: ${colors.white};
        position: fixed;
        top: 0;
      }
    `
  )}
`;

const Container = styled(Flex).attrs({
  middle: true,
})`
  height: ${HEADER_HEIGHT}px;

  ${mediaSmDown`
    height: ${MOBILE_HEADER_HEIGHT}px;
  `}
`;

const LogoContainer = styled(Box)`
  width: 200px;
  flex: 0 0 auto;
  position: relative;

  & img {
    width: 200px;
  }

  ${mediaSmDown`
    width: 180px;
    
    & img {
     width: 180px;
    }
  `}
`;

const CitiesContainer = styled(Box).attrs({
  ml: { xs: 3, sm: 8 },
  pb: { sm: 1 },
})`
  max-width: calc(100% - 158px);
`;

const ProfileContainer = styled.div`
  margin-left: auto;
`;

const ArrowIconStyled = styled(ArrowRightIcon)`
  transform: rotate(-45deg);
`;

const BusinessLink = styled(Link)`
  text-decoration: none;
`;

const Header: FC = () => {
  const { isVisibleShops, hideShops } = useContext(ShopsSAContext);
  const { isVisible, hideModal, toggleModal, showModal } = useModalState(false);
  const [isFixedHeader, setIsFixedHeader] = useState(false);

  const handleScroll = (direction: ScrollDirectionEnum) => {
    if (direction === ScrollDirectionEnum.top) {
      setIsFixedHeader(true);
      hideModal();
    } else {
      setIsFixedHeader(false);
    }
  };

  const { scrollTop } = useScrollDirection(isBrowser ? window.root : null, handleScroll, 300);

  useEffect(() => {
    if (!scrollTop) setIsFixedHeader(false);
  }, [scrollTop]);

  const handlerSelectShop: IOnSelectShop = async (shop, onShopChanged) => {
    await onShopChanged(shop);
  };
  return (
    <Wrapper>
      <StickySection
        data-testid={LANDING_HEADER_SECTION_TEST_ID}
        $isActive={isVisible || isVisibleShops}
        $isFixed={isFixedHeader || isVisibleShops}
      >
        <Container>
          {isVisibleShops && (
            <SelectShopDropdown
              onClickEditPlace={hideShops}
              from={EnumSelectShopFrom.Main}
              onSelectShop={handlerSelectShop}
              fullHeight
              onHide={hideShops}
              withCloseBtn
            />
          )}
          <LogoContainer>
            <img src={IgooodsLogoIcon} alt="igooods logo" />
          </LogoContainer>
          <CitiesContainer>
            <CitiesDropdown
              updSubdomainOnChangeCity
              isShow={isVisible}
              hide={hideModal}
              show={showModal}
              toggle={toggleModal}
              checkCity
            />
          </CitiesContainer>
          <Box hideBefore="sm" mx={5} mb={1}>
            <BusinessLink to={`${URL.how_it_works}?ref=site_head`}>
              <Flex>
                <Text color={colors.blackRussian} size={FontSize.s}>
                  Как мы работаем
                </Text>
                <Box ml={1.75} mt={0.5}>
                  <ArrowIconStyled color={colors.blackRussian} height="18" width="18" />
                </Box>
              </Flex>
            </BusinessLink>
          </Box>
          <ProfileContainer>
            <ProfileButton />
          </ProfileContainer>
        </Container>
      </StickySection>
    </Wrapper>
  );
};

export default Header;
