import { useDispatch, useSelector } from 'react-redux';
import { ICity } from 'Modules/SearchAddress/types';
import SearchAddressActions from 'Modules/SearchAddress/actions';
import Cookie from 'js-cookie';
import { REJECTED_CITY_CHECK, getCookieDomain } from 'constants/cookie';
import { updCitySubdomain } from 'Components/Header/CitiesButton/utils';
import { getCurrentCity } from 'Modules/SearchAddress/selectors';
import http from 'Modules/Shops/http';
import { DYNAMIC_PRICES_AB_TEST_NAME } from 'constants/constants';
import { setUserTest, removeUserTest } from 'Modules/AAA/actions';

interface IUseHandleSelectCity {
  onCityChange?: () => void;
  checkCity?: boolean;
  updSubdomain?: boolean;
}

export const useHandleSelectCity = ({ onCityChange, checkCity, updSubdomain = false }: IUseHandleSelectCity) => {
  const dispatch = useDispatch();
  const currentCity = useSelector(getCurrentCity);

  const setRejectCityCookie = () => {
    Cookie.set(REJECTED_CITY_CHECK, 'true', getCookieDomain());
  };

  return async (city: ICity) => {
    dispatch(SearchAddressActions.clearCurrentAddress());
    dispatch(SearchAddressActions.setCurrentCity(city));
    onCityChange && onCityChange();
    checkCity && setRejectCityCookie();
    updSubdomain && false && updCitySubdomain(currentCity.subdomain, city.subdomain);

    try {
      const abTest = await http.getUserABTest(DYNAMIC_PRICES_AB_TEST_NAME, city.id);
      if (abTest) {
        dispatch(setUserTest(DYNAMIC_PRICES_AB_TEST_NAME, abTest));
      }
    } catch (e) {
      dispatch(removeUserTest(DYNAMIC_PRICES_AB_TEST_NAME));
    }
  };
};
