import React from 'react';
import Icon, { IIcon } from './Icon';

const ForkKnifeIcon: React.FC<IIcon> = props => (
  <Icon width="64" height="64" viewBox="0 0 64 64" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.1563 8C43.429 8 41.0644 9.80407 40.4586 12.347L37.3904 25.2279C36.8692 27.416 37.8919 29.6747 39.9173 30.8084L40.2446 30.9916C40.794 31.2991 41.1317 31.8623 41.1317 32.471V50.8036C41.1317 53.6735 43.5646 56 46.5658 56C49.567 56 52 53.6735 52 50.8036V13.588C52 10.5018 49.3837 8 46.1563 8ZM46.1563 11.4286C45.1024 11.4286 44.1886 12.1257 43.9545 13.1084L40.8863 25.9893C40.7126 26.7187 41.0535 27.4716 41.7286 27.8495L42.0559 28.0327C43.7042 28.9553 44.7171 30.6447 44.7171 32.471V33.0714H48.4146V13.588C48.4146 12.3954 47.4035 11.4286 46.1563 11.4286ZM46.5658 52.5714C45.5448 52.5714 44.7171 51.7799 44.7171 50.8036V36.5H48.4146V50.8036C48.4146 51.7799 47.5869 52.5714 46.5658 52.5714Z"
      fill="#DA1F2A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3782 8C14.4079 8 12 10.3025 12 13.1429V23.7465C12 25.4923 12.9261 27.1188 14.4595 28.0662L17.1185 29.7091C17.6297 30.0249 17.9384 30.567 17.9384 31.149V51.6071C17.9384 54.0333 19.9951 56 22.5322 56C25.0693 56 27.1261 54.0333 27.1261 51.6071V31.149C27.1261 30.567 27.4348 30.0249 27.9459 29.7091L30.6049 28.0662C32.1383 27.1188 33.0644 25.4923 33.0644 23.7465V13.1429C33.0644 10.3025 30.6565 8 27.6863 8H17.3782ZM21.5238 51.6071C21.5238 52.1397 21.9753 52.5714 22.5322 52.5714C23.0891 52.5714 23.5406 52.1397 23.5406 51.6071V36.5H21.5238V51.6071ZM15.5854 13.1429C15.5854 12.1961 16.3881 11.4286 17.3782 11.4286H17.8263V16.6786C17.8263 17.6253 18.629 18.3929 19.619 18.3929C20.6091 18.3929 21.4118 17.6253 21.4118 16.6786V11.4286H23.6527V16.6786C23.6527 17.6253 24.4553 18.3929 25.4454 18.3929C26.4355 18.3929 27.2381 17.6253 27.2381 16.6786V11.4286H27.6863C28.6764 11.4286 29.479 12.1961 29.479 13.1429V23.7465C29.479 24.3284 29.1703 24.8706 28.6592 25.1864L26.0001 26.8293C24.4667 27.7767 23.5406 29.4032 23.5406 31.149V33.0714H21.5238V31.149C21.5238 29.4032 20.5977 27.7767 19.0643 26.8293L16.4053 25.1864C15.8941 24.8706 15.5854 24.3284 15.5854 23.7465V13.1429Z"
      fill="#DA1F2A"
    />
  </Icon>
);

export default ForkKnifeIcon;
