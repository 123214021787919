import React from 'react';
import Icon, { IIcon } from './Icon';

const BuhDocumentsIcon: React.FC<IIcon> = props => (
  <Icon width="64" height="64" viewBox="0 0 64 64" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.6041 22.7815C24.6041 21.7619 25.4307 20.9354 26.4503 20.9354H36.2965C37.3161 20.9354 38.1426 21.7619 38.1426 22.7815C38.1426 23.8011 37.3161 24.6277 36.2965 24.6277H26.4503C25.4307 24.6277 24.6041 23.8011 24.6041 22.7815ZM29.5278 27.7031C29.5278 26.6835 30.3544 25.8569 31.374 25.8569H36.2971C37.3167 25.8569 38.1432 26.6835 38.1432 27.7031C38.1432 28.7227 37.3167 29.5492 36.2971 29.5492H31.374C30.3544 29.5492 29.5278 28.7227 29.5278 27.7031Z"
      fill="#DA1F2A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56 50.4615V37.5385C56 34.6879 53.8464 32.3403 51.0775 32.0339V13.5385C51.0775 10.4797 48.5979 8 45.5391 8H25.8468C22.7879 8 20.3083 10.4797 20.3083 13.5385V15.3846H18.4621C15.4033 15.3846 12.9237 17.8643 12.9237 20.9231V24.6491C10.1542 24.955 8 27.3028 8 30.1538V50.4615C8 53.5203 10.4797 56 13.5385 56H50.4615C53.5203 56 56 53.5203 56 50.4615ZM19.3937 24.6154C20.8626 24.6154 22.2713 25.1989 23.31 26.2376L28.5317 31.4593C28.8779 31.8055 29.3475 32 29.8371 32H40.0006V20.9231C40.0006 19.9035 39.1741 19.0769 38.1544 19.0769H18.4621C17.4425 19.0769 16.616 19.9035 16.616 20.9231V24.6154H19.3937ZM13.5385 28.3077C12.5189 28.3077 11.6923 29.1342 11.6923 30.1538V50.4615C11.6923 51.4811 12.5189 52.3077 13.5385 52.3077H50.4615C51.4811 52.3077 52.3077 51.4811 52.3077 50.4615V37.5385C52.3077 36.5189 51.4811 35.6923 50.4615 35.6923H29.8371C28.3682 35.6923 26.9595 35.1088 25.9208 34.0701L20.6991 28.8484C20.3529 28.5022 19.8833 28.3077 19.3937 28.3077H13.5385ZM38.1544 15.3846C41.2133 15.3846 43.6929 17.8643 43.6929 20.9231V32H47.3852V13.5385C47.3852 12.5189 46.5587 11.6923 45.5391 11.6923H25.8468C24.8272 11.6923 24.0006 12.5189 24.0006 13.5385V15.3846H38.1544Z"
      fill="#DA1F2A"
    />
  </Icon>
);

export default BuhDocumentsIcon;
