import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { FontFamily, FontWeight } from '../../../theme';
import { ThemeButton } from '../../ThemedButton';
import { mediaLgDown, mediaSmDown } from '../../../theme/helpers/css';

export const Container = styled.div`
  margin-top: 120px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0 110px;
  background: ${colors.mainLandingYellow2};
  overflow: hidden;
  text-align: center;
  ${mediaSmDown`
    padding: 60px 20px 110px;
  `}
`;

export const ProductImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  object-fit: cover;
  z-index: 1;
`;

export const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  object-fit: cover;
  z-index: 2;
  width: 100%;

  ${mediaLgDown`
    display: none;
  `}
`;

export const Title = styled.span`
  font-family: ${FontFamily.secondFont};
  font-weight: ${FontWeight.medium};
  font-size: 38px;
  line-height: 44px;
  color: ${colors.dark300};
  margin-bottom: 109px;
  position: relative;
  z-index: 3;

  &:before {
    content: '';
    position: absolute;
    right: -10px;
    top: 22px;
    width: 379px;
    height: 23px;
    background: #e1efc9;
    z-index: -1;
  }
`;

export const StepsRow = styled.div`
  display: flex;
  gap: 26px;
  margin-bottom: 86px;
  position: relative;
  z-index: 3;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
`;

export const NumberContainer = styled.div`
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background: ${colors.light400};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Number = styled.span`
  font-family: ${FontFamily.secondFont};
  font-weight: ${FontWeight.medium};
  font-size: 38px;
  line-height: 44px;
  color: ${colors.primaryMain};
`;

export const Text = styled.span`
  font-family: ${FontFamily.secondFont};
  font-weight: ${FontWeight.bold};
  font-size: 18px;
  line-height: 24px;
  color: ${colors.black};
`;

export const StyledButton = styled(ThemeButton)`
  padding: 21px 52px;
  position: relative;
  z-index: 3;
`;
