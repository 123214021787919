import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { FontFamily, FontWeight } from '../../../theme';
import { mediaSmDown } from '../../../theme/helpers/css';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 97px;
  text-align: center;
`;

export const Map = styled.div`
  position: relative;
  height: 572px;
  width: 100%;
  grid-area: map;
  display: block;
`;

export const Title = styled.span`
  font-family: ${FontFamily.secondFont};
  font-weight: ${FontWeight.medium};
  font-size: 38px;
  line-height: 44px;
  color: ${colors.dark300};
  margin-bottom: 38px;

  ${mediaSmDown`
     padding: 0 20px;
  `}
`;
