import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getCurrentCity } from 'Modules/SearchAddress/selectors';
import { ICity } from 'Modules/SearchAddress/types';
import { mediaSmDown, mediaSmUp } from 'theme/helpers/css';
import { FontSize } from 'theme';
import Text from 'Components/Typography/Text';
import { mediaMdDown, mediaXlDown } from 'theme/helpers/css';
import Flex from 'Components/Grid/Flex';
import { colors } from 'constants/colors';
import { getCitiesList } from 'Modules/SearchAddress/selectors';
import { getHeightForListCities } from './utils';
import IconSelect from 'assets/images/select.svg';
import { getTypographyFontStyle } from 'theme/selectors';
import { Box } from '@igooods/ui-kit';
import { SATitle } from 'Containers/SelectAddress/Search/Desktop';
import { Highlight, highlightStyles } from 'Components/HighlightText';

export const CITIES_DROPDOWN_LIST_TEST_ID = 'CITIES_DROPDOWN_LIST_TEST_ID';

const List = styled(Flex).attrs({ wrap: true, column: true, mt: { xs: 5, sm: 7.5 }, mb: -4 })<{
  length: number;
}>`
  width: 100%;
  max-height: ${getHeightForListCities(5)}px;
  ${mediaXlDown`max-height: ${getHeightForListCities(4)}px;`}
  ${mediaMdDown`max-height: ${getHeightForListCities(3)}px;`}
  ${mediaSmDown`max-height: ${getHeightForListCities(1)}px;`}
`;

interface ICityItem {
  selected: boolean;
}

const CityItem = styled(Text).attrs({ size: FontSize.m, color: colors.blackRussian })<ICityItem>`
  max-width: 239px;
  width: 100%;
  height: 28px;
  user-select: none;
  cursor: pointer;
  margin-bottom: 16px;
  display: flex;
  align-items: center;

  ${({ selected }) =>
    selected &&
    `
    &:before {
      content: '';
      background-image: url(${IconSelect});
      width: 14px;
      height: 9px;
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 6px;
    }
  `}

  ${mediaSmDown`
    max-width: unset;
    justify-content: space-between;

    &:before {
      order: 2;
    }

    ${getTypographyFontStyle(FontSize.l)}

    ${({ selected }: { selected: boolean }) => selected && 'order: -1;'}
  `}

  ${mediaSmUp`
    ${Highlight}{
      line-height: 22px;
    }

    ${({ selected }: ICityItem) => !selected && `&:hover{${Highlight}{${highlightStyles}}}`}
  `}
`;

interface ICitiesList {
  onCityChange: (city: ICity) => void;
  className?: string;
}

const CitiesList: FC<ICitiesList> = ({ className, onCityChange }) => {
  const currentCity = useSelector(getCurrentCity);
  const cities = useSelector(getCitiesList);
  const [selectedCity, setSelectedCity] = useState<ICity>(currentCity);

  useEffect(() => {
    if (currentCity && typeof currentCity !== 'string') {
      setSelectedCity(currentCity);
    }
  }, [currentCity]);

  return (
    <div className={className}>
      <Box mr={{ xs: 16 }}>
        <SATitle>Выберите город</SATitle>
      </Box>
      <List length={cities.length} data-testid={CITIES_DROPDOWN_LIST_TEST_ID}>
        {cities.map(item => (
          <CityItem selected={item.id === selectedCity.id} onClick={() => onCityChange(item)} key={String(item.id)}>
            <Highlight>{item.name}</Highlight>
          </CityItem>
        ))}
      </List>
    </div>
  );
};

export default CitiesList;
