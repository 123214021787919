import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { FontFamily, FontWeight } from '../../../theme';
import { mediaSmDown } from '../../../theme/helpers/css';

export const Row = styled.div`
  display: flex;
  position: relative;
  z-index: 0;
  margin-top: -40px;
  gap: 23px;
  justify-content: center;
  flex-wrap: wrap;

  ${mediaSmDown`
     margin-top: 20px;
     padding: 0 20px;
  `}
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: ${colors.white};
  border-radius: 24px;
  box-shadow: 0px 8px 16px 0px #60617029;
  padding: 10px 20px 25px;
  align-items: center;
  min-width: 210px;
`;

export const Text = styled.span`
  font-family: ${FontFamily.secondFont};
  font-weight: ${FontWeight.semiBold};
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  white-space: pre-wrap;
`;
