import React from 'react';
import { IProductsGroup } from 'Modules/Shops/types';
import { Container, LogoRow, Text, TextContainer, LogoItem } from './styled';

interface IMainLandingShops {
  groups?: IProductsGroup[];
}

export const MainLandingShops: React.FC<IMainLandingShops> = ({ groups }) => {
  if (!groups?.length) {
    return null;
  }

  return (
    <Container>
      <TextContainer>
        <Text>
          Да! Теперь мы доставляем продукты и товары <br /> из магазинов
        </Text>
      </TextContainer>
      <LogoRow>
        {groups.map(({ seo_name, logo }) => (
          <LogoItem key={seo_name} imageSrc={logo.logo_shop_path} />
        ))}
      </LogoRow>
    </Container>
  );
};
