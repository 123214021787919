import React from 'react';
import { Block, Container, Description, MainTitle, ManImage, SearchAddressWrapper } from './styled';
import manImageUrl from './images/man.png';
import SearchAddress from '../LandingSearchAddress/SearchAddressBlock';
import { EnumInputTheme } from '../../../constants/enums';
import Header from '../HeaderTransparent';

export const MainLandingTopBlock = () => {
  return (
    <Container>
      <Header />
      <ManImage src={manImageUrl} />
      <Block>
        <h1>
          <MainTitle>
            Доставляем продукты <br />
            из любимых магазинов
          </MainTitle>
        </h1>
        <Description>Тот самый. Надежный. Поставщик для вашего бизнеса</Description>
        <SearchAddressWrapper>
          <SearchAddress use={EnumInputTheme.roundBorder} hideButton />
        </SearchAddressWrapper>
      </Block>
    </Container>
  );
};
