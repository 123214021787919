import ForkKnifeIcon from '../../Icon/ForkKnifeIcon';
import React from 'react';
import ClockIcon from '../../Icon/ClockIcon';
import PaymentDelayIcon from '../../Icon/PaymentDelayIcon';
import PaymentMethod from '../../Icon/PaymentsMethodIcon';
import BuhDocumentsIcon from '../../Icon/BuhDocumentsIcon';
import { Block, Row, Text } from './styled';

const BLOCKS = [
  {
    icon: <ForkKnifeIcon />,
    text: 'Широкий \n ассортимент',
  },
  {
    icon: <ClockIcon />,
    text: 'Доставка \n от 60 минут',
  },
  {
    icon: <PaymentDelayIcon />,
    text: 'Отсрочка платежа \n с 1-го заказа',
  },
  {
    icon: <PaymentMethod />,
    text: 'Различные способы \n оплаты',
  },
  {
    icon: <BuhDocumentsIcon />,
    text: 'Документы \n для бухгалтерии',
  },
];

export const MainLandingInfoRow = () => {
  return (
    <Row>
      {BLOCKS.map(block => (
        <Block key={block.text}>
          {block.icon}
          <Text>{block.text}</Text>
        </Block>
      ))}
    </Row>
  );
};
