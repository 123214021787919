import React from 'react';
import capImgSrc from './images/cap.png';
import redBlockImgSrc from './images/red.png';
import SearchAddress from '../LandingSearchAddress/SearchAddressBlock';
import { EnumInputTheme } from '../../../constants/enums';
import { CapImage, Container, RedBlockImage, Title } from './styled';

export const MainLandingShopSelect = () => {
  return (
    <Container>
      <Title>Начнем, коллеги?</Title>
      <SearchAddress use={EnumInputTheme.roundBorderGrayPlaceholder} />
      <RedBlockImage src={redBlockImgSrc} alt="red-block" />
      <CapImage src={capImgSrc} alt="cap" />
    </Container>
  );
};
