import React from 'react';
import Icon, { IIcon } from './Icon';

const ClockIcon: React.FC<IIcon> = props => (
  <Icon width="64" height="64" viewBox="0 0 64 64" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 22C31.1046 22 32 22.8954 32 24V34H42C43.1046 34 44 34.8954 44 36C44 37.1046 43.1046 38 42 38H30.8C29.2536 38 28 36.7464 28 35.2V24C28 22.8954 28.8954 22 30 22Z"
      fill="#DA1F2A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 18C23.1634 18 16 25.1634 16 34C16 42.8366 23.1634 50 32 50C40.8366 50 48 42.8366 48 34C48 25.1634 40.8366 18 32 18ZM12 34C12 22.9543 20.9543 14 32 14C43.0457 14 52 22.9543 52 34C52 45.0457 43.0457 54 32 54C20.9543 54 12 45.0457 12 34Z"
      fill="#DA1F2A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.4142 10.5858C22.1953 11.3668 22.1953 12.6332 21.4142 13.4142L11.4142 23.4142C10.6332 24.1953 9.36683 24.1953 8.58579 23.4142C7.80474 22.6332 7.80474 21.3668 8.58579 20.5858L18.5858 10.5858C19.3668 9.80474 20.6332 9.80474 21.4142 10.5858Z"
      fill="#DA1F2A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.5858 10.5858C41.8047 11.3668 41.8047 12.6332 42.5858 13.4142L52.5858 23.4142C53.3668 24.1953 54.6332 24.1953 55.4142 23.4142C56.1953 22.6332 56.1953 21.3668 55.4142 20.5858L45.4142 10.5858C44.6332 9.80474 43.3668 9.80474 42.5858 10.5858Z"
      fill="#DA1F2A"
    />
  </Icon>
);

export default ClockIcon;
