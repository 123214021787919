import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { FontFamily, FontWeight } from '../../../theme';
import bgSrc from './images/backgroud.png';
import { mediaLgDown, mediaSmDown } from '../../../theme/helpers/css';

export const Container = styled.div`
  margin-top: 230px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  margin-bottom: 300px;
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    width: 993px;
    height: 523px;
    top: -105px;
    background-image: url(${bgSrc});
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
  }
`;

export const Title = styled.span`
  font-family: ${FontFamily.secondFont};
  font-weight: ${FontWeight.medium};
  font-size: 38px;
  line-height: 44px;
  color: ${colors.dark300};
  position: relative;
  z-index: 3;

  ${mediaSmDown`
    padding: 0 20px;
  `}
`;

export const CapImage = styled.img`
  position: absolute;
  top: 110px;
  right: 70px;
  z-index: 1;

  ${mediaLgDown`
    display: none;
  `}
`;

export const RedBlockImage = styled.img`
  position: absolute;
  top: 200px;
  right: 255px;
  z-index: 2;

  ${mediaLgDown`
    display: none;
  `}
`;
