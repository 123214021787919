import styled from 'styled-components';
import { colors } from '../../../constants/colors';
import { FontFamily, FontWeight } from '../../../theme';
import { mediaSmDown } from '../../../theme/helpers/css';

export const Container = styled.div`
  margin-top: 97px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const Title = styled.span`
  font-family: ${FontFamily.secondFont};
  font-weight: ${FontWeight.medium};
  font-size: 38px;
  line-height: 44px;
  color: ${colors.dark300};
  margin-bottom: 40px;
`;

export const CategoriesRow = styled.div`
  display: flex;
  gap: 37px;
  flex-wrap: wrap;
  justify-content: center;

  ${mediaSmDown`
    padding: 0 20px;
  `}
`;

export const Category = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  text-align: center;
`;

export const Text = styled.span`
  font-family: ${FontFamily.secondFont};
  font-weight: ${FontWeight.medium};
  font-size: 18px;
  line-height: 24px;
  color: ${colors.black};
`;
