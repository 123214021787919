import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import ThemeButton from 'Components/ThemedButton';
import { EnumGtagEvents, EnumInputSize, EnumInputTheme, EnumTheme } from 'constants/enums';
import { Box, Flex } from '@igooods/ui-kit';
import Input from 'Components/Input/Input';
import { getCurrentPosition } from 'Modules/SearchAddress/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentAddressName, getCurrentCityId, checkIsCoordinate } from 'Modules/SearchAddress/selectors';
import { FontFamily, FontSize, FontWeight, Transitions } from 'theme';
import { colors } from 'constants/colors';
import Text from 'Components/Typography/Text';
import { addStyleIfPropTruly } from 'utils/styledComponents';
import { BottomSheetSAContext } from 'Components/landings/LandingSearchAddress/context';
import { useHistory } from 'react-router';
import { setSAToLocation } from 'Components/landings/LandingSearchAddress/utils';
import analyticsManager from 'utils/tagManager/manager';
import { IGtagOptions } from './SearchAddressBlock';
import { isFirstVisitSearchAddress } from 'Components/SearchAddress_new/utils';

export const LANDING_SA_MOBILE_TEST_ID = 'LANDING_SA_MOBILE_TEST_ID';
export const LANDING_SA_MOBILE_BTN_TEST_ID = 'LANDING_SA_MOBILE_BTN_TEST_ID';
export const LANDING_SA_MOBILE_INPUT_TEST_ID = 'LANDING_SA_MOBILE_INPUT_TEST_ID';

const placeholder = 'Укажите адрес доставки';

const InputWrap = styled(Box).attrs({ width: '100%' })<{ $isHide: boolean }>`
  ${addStyleIfPropTruly('$isHide', 'display: none;')}
`;

const MapBtn = styled(Flex).attrs({ px: 3.5, center: true, middle: true })`
  border-radius: 100px;
  height: 38px;
  cursor: pointer;
  transition: ${Transitions.bg};
  &:hover {
    background-color: ${colors.titanWhite};
  }
`;

// need for focus on iphone
const FakeInput = styled.input<{ $isHide: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  ${addStyleIfPropTruly('$isHide', 'display: none;')}
`;

interface ISearchAddressMobile {
  use?: EnumInputTheme;
  gtagOptions?: IGtagOptions;
}

const SearchAddressMobile: FC<ISearchAddressMobile> = ({ use }) => {
  const dispatch = useDispatch();
  const cityId = useSelector(getCurrentCityId);
  const { isOpenBottomSheet, openBottomSheetSA } = useContext(BottomSheetSAContext);
  const history = useHistory();
  const addressName = useSelector(getCurrentAddressName);
  const addressWithCoordinate = useSelector(checkIsCoordinate);
  const isValidAddress = addressName && addressWithCoordinate;

  const handleOpenSA = () => {
    setSAToLocation(history);
  };

  const handleOpenBottomSheetSA = () => {
    if (isFirstVisitSearchAddress() && navigator.geolocation) {
      getCurrentPosition(
        dispatch,
        cityId,
        () => {
          analyticsManager.gtag(EnumGtagEvents.SELECT_ADDRESS, { from: 'select_address' });
          handleOpenSA();
        },
        openBottomSheetSA
      );
    } else {
      openBottomSheetSA();
    }
  };

  return (
    <Flex column grow mt={5.5} data-testid={LANDING_SA_MOBILE_TEST_ID}>
      {isValidAddress && (
        <Box width="100%">
          <InputWrap $isHide={isOpenBottomSheet}>
            <Input
              onClick={handleOpenBottomSheetSA}
              name="address_dropdown"
              size={EnumInputSize.large}
              use={use}
              placeholder={placeholder}
              value={addressName}
              testId={LANDING_SA_MOBILE_INPUT_TEST_ID}
              after={
                <MapBtn onClick={handleOpenSA}>
                  <Text
                    fontFamily={FontFamily.secondFont}
                    size={FontSize.m}
                    color={colors.blackRussian}
                    fontWeight={FontWeight.semiBold}
                  >
                    Карта
                  </Text>
                </MapBtn>
              }
              readOnly
            />
          </InputWrap>
        </Box>
      )}
      <Box mt={3.5}>
        <ThemeButton
          testId={LANDING_SA_MOBILE_BTN_TEST_ID}
          size={EnumInputSize.large}
          use={EnumTheme.roundRed}
          onClick={handleOpenSA}
          fluid
        >
          Выбрать магазин
          {!isValidAddress && <FakeInput $isHide={isOpenBottomSheet} readOnly />}
        </ThemeButton>
      </Box>
    </Flex>
  );
};

export default SearchAddressMobile;
