import React, { useRef } from 'react';
import styled from 'styled-components';
import { useCityByIpIsDifferent } from 'Modules/SearchAddress/hooks';
import { colors } from 'constants/colors';
import { FontSize } from 'theme';
import ThemedButton from 'Components/ThemedButton';
import { Box } from '@igooods/ui-kit';
import Flex from 'Components/Grid/Flex';
import useModalState from 'utils/hooks/useModalState';
import { ICity } from 'Modules/SearchAddress/types';
import Cookie from 'js-cookie';
import { REJECTED_CITY_CHECK, getCookieDomain } from 'constants/cookie';
import { EnumButtonSize, EnumTheme } from 'constants/enums';
import Text from 'Components/Typography/Text';
import { FontFamily, FontWeight } from 'theme';
import { Spring, animated } from 'react-spring';
import CloseButtonIcon from 'Components/Icon/CloseButtonIcon';
import useScrollDirection, { ScrollDirectionEnum } from 'utils/hooks/useScrollDirection';
import { isBrowser } from 'utils/helpers';
import { useOnClickOutside } from 'utils/hooks';
import { shadows } from 'constants/shadows';

export const CHECK_CITY_DROPDOWN_TEST_ID = 'CHECK_CITY_DROPDOWN_TEST_ID';
export const CHECK_CITY_DROPDOWN_ACCEPT_BTN_TEST_ID = 'CHECK_CITY_DROPDOWN_ACCEPT_BTN_TEST_ID';
export const CHECK_CITY_DROPDOWN_DISCARD_BTN_TEST_ID = 'CHECK_CITY_DROPDOWN_DISCARD_BTN_TEST_ID';

const Container = styled(animated.div)`
  position: absolute;
  top: 60px;
  background: ${colors.white};
  border-radius: 24px;
  left: 180px;
  padding: 22px 22px 28px;
  box-shadow: ${shadows.boxShadow};
`;

const CloseButton = styled.div`
  position: absolute;
  right: 4px;
  top: 4px;
  padding: 12px;
  cursor: pointer;
`;

const Button = styled(ThemedButton)`
  padding-right: 28px;
  padding-left: 28px;
`;

interface ICheckCityDropdown {
  onCityChange: (city: ICity) => void;
  showCities?: () => void;
}

const CheckCityDropdown: React.FC<ICheckCityDropdown> = ({ onCityChange, showCities }) => {
  const differentCity = useCityByIpIsDifferent();
  const { isVisible, hideModal } = useModalState(true);
  const containerNode = useRef<HTMLDivElement>(null);

  const setRejectCityDropdown = () => {
    hideModal();
    Cookie.set(REJECTED_CITY_CHECK, 'true', getCookieDomain());
  };

  useOnClickOutside(containerNode, () => {
    setRejectCityDropdown();
  });

  const handleScroll = (direction: ScrollDirectionEnum) => {
    if (direction === ScrollDirectionEnum.bottom) {
      setRejectCityDropdown();
    }
  };

  useScrollDirection(isBrowser ? window.root : null, handleScroll, 300);

  const onAccept = () => {
    differentCity && onCityChange(differentCity);
  };

  const changeCityHandler = () => {
    showCities && showCities();
    setRejectCityDropdown();
  };

  return differentCity?.id && isVisible ? (
    <Spring from={{ opacity: 0, transform: 'translateY(-10px)' }} to={{ opacity: 1, transform: 'translateY(0)' }}>
      {styles => (
        <Container style={styles} ref={containerNode}>
          <Box data-testid={CHECK_CITY_DROPDOWN_TEST_ID} mb={5}>
            <Text
              fontWeight={FontWeight.semiBold}
              fontFamily={FontFamily.secondFont}
              color={colors.black}
              size={FontSize.s}
            >
              Вы в {differentCity?.declensions.prepositional}?
            </Text>
          </Box>
          <Flex>
            <Box mr={3.5}>
              <Button
                use={EnumTheme.roundRed}
                size={EnumButtonSize.small}
                onClick={onAccept}
                testId={CHECK_CITY_DROPDOWN_ACCEPT_BTN_TEST_ID}
              >
                Да
              </Button>
            </Box>
            <Button
              size={EnumButtonSize.small}
              use={EnumTheme.roundRed}
              onClick={changeCityHandler}
              testId={CHECK_CITY_DROPDOWN_DISCARD_BTN_TEST_ID}
            >
              Изменить город
            </Button>
          </Flex>
          <CloseButton onClick={hideModal}>
            <CloseButtonIcon width="12" height="12" color={colors.warmGrey} />
          </CloseButton>
        </Container>
      )}
    </Spring>
  ) : null;
};

export default CheckCityDropdown;
