import React from 'react';
import { CoverageZonesMapBuilder } from '../../../Containers/SelectAddress/MapBuilder';
import { Container, Title, Map } from './styled';
import { useSelector } from 'react-redux';
import { getCitiesList, getCurrentCityId } from '../../../Modules/SearchAddress/selectors';

export const MainLandingMap = () => {
  const currentCityId = useSelector(getCurrentCityId);
  const cities = useSelector(getCitiesList);
  const currentCityInfo = cities.find(city => city.id === currentCityId);

  const getCityFullName = (id: number) => {
    if (!id) return 'Москве и Московской области';

    switch (id) {
      case 1:
        return `${currentCityInfo?.declensions?.dative} и Ленинградской области`;
      case 4:
        return `${currentCityInfo?.declensions?.dative} и Московской области`;
      default:
        return currentCityInfo?.declensions?.dative;
    }
  };

  const resultText = `Да, доставляем по ${getCityFullName(currentCityId)}`;

  return (
    <Container>
      <Title>{resultText}</Title>
      <Map>
        <CoverageZonesMapBuilder />
      </Map>
    </Container>
  );
};
