import React, { FC, useContext } from 'react';
import SearchAddressMobile from './SearchAddressMobile';
import { Box } from '@igooods/ui-kit';
import { EnumInputTheme } from 'constants/enums';
import { ShopsSAContext } from './context';
import { SearchAddressFromType } from 'Components/SearchAddress_new/constants';
import SearchAddressDesktop from 'Components/SearchAddress_new/Desktop';

export interface IGtagOptions {
  click_map_properties?: any;
  click_call_to_action_properties?: any;
}

interface ILandingSearchAddress {
  use?: EnumInputTheme;
  gtagOptions?: IGtagOptions;
  hideButton?: boolean;
}

const LandingSearchAddress: FC<ILandingSearchAddress> = ({
  use = EnumInputTheme.roundSecondary,
  gtagOptions,
  hideButton = false,
}) => {
  const { showShops } = useContext(ShopsSAContext);

  return (
    <>
      <Box hideBefore="sm">
        <SearchAddressDesktop
          use={use}
          from={SearchAddressFromType.main}
          onSelectAddress={showShops}
          hideButton={hideButton}
        />
      </Box>
      <Box hideAfter="sm">
        <SearchAddressMobile use={use} gtagOptions={gtagOptions} />
      </Box>
    </>
  );
};

export default LandingSearchAddress;
