import H from 'history';

export const clearSAFromLocation = (history: H.History) => {
  const params = new URLSearchParams(history.location.search);
  if (params.get('select_address')) {
    params.delete('select_address');
    history.push({ search: params.toString() });
  }
};

export const setSAToLocation = (history: H.History) => {
  const params = new URLSearchParams(history.location.search);
  params.set('select_address', 'true');
  history.push({ search: params.toString() });
};
