import React, { FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCity } from 'Modules/SearchAddress/selectors';
import CitiesList from 'Components/Header/CitiesButton/CitiesList';
import HeaderDropdown from 'Components/Header/HeaderDropdown';
import useModalState from 'utils/hooks/useModalState';
import CheckCityDropdown from 'Components/Header/CitiesButton/CheckCityDropdown';
import { ICity } from 'Modules/SearchAddress/types';
import Cookie from 'js-cookie';
import { REJECTED_CITY_CHECK } from 'constants/cookie';
import { useUserAgentContext } from 'utils/userAgent/context';
import { useHandleSelectCity } from 'Components/Header/CitiesButton/hooks';
import { Button, ButtonText, Chevron } from './styled';

export const CITIES_BUTTON_DROPDOWN_TEST_ID = 'CITIES_BUTTON_DROPDOWN_TEST_ID';

interface ICitiesDropdown {
  className?: string;
  checkCity?: boolean;
  updSubdomainOnChangeCity?: boolean;
  isShow?: boolean;
  hide?: () => void;
  show?: () => void;
  toggle?: () => void;
  onCityChange?: () => void;
}

const CitiesDropdown: FC<ICitiesDropdown> = ({
  className,
  checkCity,
  updSubdomainOnChangeCity,
  isShow,
  hide,
  show,
  toggle,
  onCityChange,
}) => {
  const currentCity = useSelector(getCurrentCity);
  const { isVisible, hideModal, toggleModal } = useModalState();
  const { isMobile } = useUserAgentContext();
  const updCity = useHandleSelectCity({ onCityChange, checkCity, updSubdomain: updSubdomainOnChangeCity });

  const onHide = () => {
    hideModal();
    hide && hide();
  };

  const handleSelectCity = useCallback(
    async (city: ICity) => {
      await updCity(city);

      if (isVisible) {
        hide && hide();
        hideModal();
      }
    },
    [hide, hideModal, isVisible, updCity]
  );

  const onToggle = () => {
    toggleModal();
    toggle && toggle();
  };

  const isShowCheckCity = checkCity && !isMobile && !Cookie.get(REJECTED_CITY_CHECK);

  return (
    <>
      <HeaderDropdown
        isOpen={isShow ?? isVisible}
        onToggle={onToggle}
        onHide={onHide}
        withCloseBtn
        toggle={
          <Button data-testid={CITIES_BUTTON_DROPDOWN_TEST_ID} className={className}>
            <ButtonText>{currentCity.name}</ButtonText>
            <Chevron isOpen={isShow ?? isVisible} width="8" />
          </Button>
        }
      >
        <CitiesList onCityChange={handleSelectCity} />
      </HeaderDropdown>
      {isShowCheckCity && <CheckCityDropdown onCityChange={handleSelectCity} showCities={show} />}
    </>
  );
};

export default memo(CitiesDropdown);
